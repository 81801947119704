import React from 'react'
import "./AboutUsMobile.css";
import "../../home/mobile/HomeMobile.css"
import CarImgOne from "../../../../assets/images/about-mob-car-one.jpg"
import CarImgTwo from "../../../../assets/images/about-mob-car-two.jpg"
import CarImgThree from "../../../../assets/images/about-mob-car-three.jpg"
import OurStory from "../../../../assets/images/mob-our-story.jpg";
import OmIconOne from "../../../../assets/images_backup/our-mission-icon-five.svg";
import OmIconTwo from "../../../../assets/images_backup/our-mission-icon-two.svg";
import OmIconThree from "../../../../assets/images_backup/our-mission-icon-three.svg";
import OmIconFour from "../../../../assets/images_backup/our-mission-icon-four.svg";
import OmIconFive from "../../../../assets/images_backup/our-mission-icon-five.svg";
import MobGoldMini from "../../../../assets/images/mob-gold-mining.jpg"
import FkbCardOne from "../../../../assets/images/mob-fkb-img-one.jpg"
import FkbCardTwo from "../../../../assets/images/mob-fkb-img-two.jpg"
import FkbCardThree from "../../../../assets/images/mob-fkb-img-three.jpg"
import KmuImg from "../../../../assets/images/mob-kmu-img.jpg";
import CoreAssets from "../../../../assets/images/mob-core-assets-img.jpg";
import Star from "../../../../assets/images_backup/awards-icon.svg";
import Award from "../../../../assets/images_backup/star-icon.svg";
import Mep from "../../../../assets/images/mob-mep.jpg"

function AboutUsMobile() {
  return (
    <div>
      <div className='about-mobile-carousel-container'>
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src={CarImgOne} alt="First slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={CarImgTwo} alt="Second slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={CarImgThree} alt="Third slide" />
            </div>
          </div>
        </div>
        <div className='about-car-content'>
          <div className="about-title-one"><span>What we do </span><div className='ato-span'></div></div>
          <div className='mob-about-title-two'>About us</div>
          <div className='mob-about-title-three'>FOSTERING PROSPERITY, SUSTAINING GROWTH</div>
        </div>
      </div>

      <div className='mob-our-story'>
        <div className='mos-img'><img src={OurStory} alt='...' /></div>
        <div className='mos-text'>GDS Mining, founded in 2006, has evolved into a key player in resource extraction, particularly in mining since 2018. Guided by its core values of INSIGHT, INSPIRATION, and INTEGRITY, the company emphasizes innovation, ethical conduct, and strategic partnerships for long-term growth. With a focus on sustainability and leveraging technology, GDS Mining is well-positioned to lead in the mining industry while maintaining its reputation as a reliable and progressive partner across sectors.</div>
      </div>

      <div className='mob-our-mission-container'>
        <div className='mob-om-head'>OUR MISSION</div>
        <div className='mob-om-text'>GDS Mining drives sustainable, responsible mining across sectors like defense safety, agribusiness, and equipment supply. With expertise and innovative technology, we ensure efficient mineral extraction. Committed to excellence, environmental care, and partnerships, we deliver solutions with integrity, benefiting clients, stakeholders, and communities alike.</div>
        <div className='mob-om-card-container'>
          <div className='mob-om-card'>
            <div className='moc-image'><img src={OmIconOne} alt='...' /></div>
            <div className='moc-text-one'>SUSTAINABILITY</div>
            <div className='moc-text'>Prioritize eco-friendly practices in every mining and development phase to safeguard natural resources for future generations.</div>
          </div>
          <div className='mob-om-card'>
            <div className='moc-image'><img src={OmIconTwo} alt='...' /></div>
            <div className='moc-text-one'>VALUE CREATION</div>
            <div className='moc-text'>Deliver long-term value to clients, shareholders, and communities by executing projects efficiently and ethically.</div>
          </div>
          <div className='mob-om-card'>
            <div className='moc-image'><img src={OmIconThree} alt='...' /></div>
            <div className='moc-text-one'>INNOVATION</div>
            <div className='moc-text'>Stay ahead of the curve by continually adopting new technologies and processes, driving industry innovation and sustainability.</div>
          </div>
          <div className='mob-om-card'>
            <div className='moc-image'><img src={OmIconFour} alt='...' /></div>
            <div className='moc-text-one'>SAFETY AND COMPLIANCE</div>
            <div className='moc-text'>Maintain the highest safety standards and ensure strict adherence to global safety regulations for all employees and operations.</div>
          </div>
          <div className='mob-om-card'>
            <div className='moc-image'><img src={OmIconFive} alt='...' /></div>
            <div className='moc-text-one'>COMMUNITY AND PARTNERSHIP</div>
            <div className='moc-text'>Build strong, collaborative relationships with local communities, governments, and partners to foster mutual growth and success.</div>
          </div>
        </div>
      </div>

      <div className='mob-gold-mining'>
        <img src={MobGoldMini} alt='...' className='mgm-img' />
        <div className='mgm-text-container'>
          <div className='mtc-one'>GOLD MINING</div>
          <div className='mtc-two'>GDS Mining showcases a versatile business portfolio with a strong focus on excellence across several sectors, particularly in gold mining. The company specializes in both in-situ and alluvial extraction methods, allowing it to tap into diverse gold reserves. By employing modern and environmentally friendly techniques, GDS Mining enhances operational efficiency and output while minimizing its ecological impact.</div>
        </div>
      </div>

      <div className='mob-fkb-container'>
        <div className='mfkb-head'>FOCUS KEY BUSINESSES</div>
        <div className='mob-fkb-card'>
          <img src={FkbCardOne} alt='...' className='fkb-mob-image' />
          <div className='mob-fkb-card-content'>
            <div className='mfcc-head'>MINING</div>
            <div className='mfcc-text'>GDS Mining excels in gold extraction using both in-situ and alluvial methods, combining modern, eco-friendly techniques to boost efficiency and minimize environmental impact.</div>
          </div>
        </div>

        <div className='mob-fkb-card'>
          <img src={FkbCardTwo} alt='...' className='fkb-mob-image' />
          <div className='mob-fkb-card-content'>
            <div className='mfcc-head'>AGRI BUSINESS</div>
            <div className='mfcc-text'>GDS Mining supports sustainable agriculture by distributing products, partnering with farmers, and promoting advanced technologies to enhance productivity and food security.</div>
          </div>
        </div>

        <div className='mob-fkb-card'>
          <img src={FkbCardThree} alt='...' className='fkb-mob-image' />
          <div className='mob-fkb-card-content'>
            <div className='mfcc-head'>SUPPLY OF EQUIPMENT & MACHINERY</div>
            <div className='mfcc-text'>GDS Mining supplies durable machinery for mining, construction, and agriculture, ensuring efficient operations with strong after-sales support and maintenance.</div>
          </div>
        </div>
      </div>

      {/* <div className='mob-okm-container'>
        <div className='mob-okm-head'>OUR KEY MILESTONES</div>
        <div className='mob-okm-card-container'>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Experties</div>
            <div className='mokmc-text-one'>18+</div>
            <div className='mokmc-text-two'>Years of expertise</div>
          </div>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Growth</div>
            <div className='mokmc-text-one'>45%</div>
            <div className='mokmc-text-two'>In Last 2 Financial Year</div>
          </div>
        </div>
        <div className='mob-okm-card-container'>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Global Presence</div>
            <div className='mokmc-text-one'>07</div>
            <div className='mokmc-text-two'>Global partnership</div>
          </div>
          <div className='mob-okm-card'>
            <div className='mokmc-head'>Employees</div>
            <div className='mokmc-text-one'>70+</div>
            <div className='mokmc-text-two'>Active Work Force</div>
          </div>
        </div>
      </div> */}

      <div className='mob-kmu-container'>
        <img src={KmuImg} alt='...' className='mkc-img' />
      </div>

      <div className='mob-core-assets-container'>
        <div className='mob-ca-head'>CORE ASSETS</div>
        <div style={{ width: "70%" }}><img src={CoreAssets} alt='...' style={{ width: "100%" }} /></div>
        <div className='mca-text'>We emphasise partnership and collaboration among individuals to foster and sustain a cooperative and innovative business environment.</div>
      </div>

      <div className='mob-ana'>
        <div class="mob-ana-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Award} alt="Icon" className='mob-ana-award-icon' />
            <div class="mob-ana-header">Awards and Achievements</div>
          </div>


          <div class="mob-ana-award" style={{ marginTop: "70px" }}>
            <div class="mob-ana-award-icon">
              <img src={Star} alt="Icon" className='mob-ana-star' />
            </div>
            <div class="mob-ana-award-content">
              <div class="mob-ana-award-title">Outstanding Entrepreneurs Award 2021</div>
              <div class="mob-ana-award-description">Recognized GDS Mining's innovation and leadership in expanding operations across mining and agribusiness.</div>
            </div>
          </div>

          <div class="mob-ana-award">
            <div class="mob-ana-award-icon">
              <img src={Star} alt="Icon" className='mob-ana-star' />            </div>
            <div class="mob-ana-award-content">
              <div class="mob-ana-award-title">Premium Sponsorship Award, Youth Union, Lao PDR, 2024</div>
              <div class="mob-ana-award-description">Honoured GDS Mining's support for youth initiatives, promoting education and training.</div>
            </div>
          </div>

          <div class="mob-ana-award">
            <div class="mob-ana-award-icon">
              <img src={Star} alt="Icon" className='mob-ana-star' />            </div>
            <div class="mob-ana-award-content">
              <div class="mob-ana-award-title">Young Entrepreneurship Association Membership, 2021</div>
              <div class="mob-ana-award-description">Highlighted GDS Mining's role in fostering young entrepreneurs and SMEs.</div>
            </div>
          </div>

          <div class="mob-ana-award">
            <div class="mob-ana-award-icon">
              <img src={Star} alt="Icon" className='mob-ana-star' />            </div>
            <div class="mob-ana-award-content">
              <div class="mob-ana-award-title">Certificate of Appreciation, Lao Youth Union, 2023</div>
              <div class="mob-ana-award-description">Acknowledged GDS Mining's contributions to youth education and skill-building programs.</div>
            </div>
          </div>

          <div class="mob-ana-award">
            <div class="mob-ana-award-icon">
              <img src={Star} alt="Icon" />            </div>
            <div class="mob-ana-award-content">
              <div class="mob-ana-award-title">Certificate of Appreciation, Department of Energy and Mines, 2022</div>
              <div class="mob-ana-award-description">Recognized responsible mining practices and contributions to sustainable development.</div>
            </div>
          </div>

          <div class="mob-ana-award" style={{ marginBottom: "30px" }}>
            <div class="mob-ana-award-icon">
              <img src={Star} alt="Icon" />            </div>
            <div class="mob-ana-award-content">
              <div class="mob-ana-award-title">Additional Recognitions</div>
              <div class="mob-ana-award-description">GDS Mining has been widely appreciated for its CSR and environmental sustainability efforts.</div>
            </div>
          </div>
        </div>
      </div>

      <div className='mob-mep-container'>
        <img src={Mep} alt='...' style={{ width: "100%" }} />
      </div>
    </div>
  )
}

export default AboutUsMobile
