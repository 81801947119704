import React from 'react';
import './FooterDesktop.css'; 
// import Instagram from "../../../../assets/images_backup/instagram-icon.svg";
// import Facebook from "../../../../assets/images_backup/facebook-icon.svg";
// import Youtube from "../../../../assets/images_backup/youtube-icon.svg";
// import Twitter from "../../../../assets/images_backup/twitter-icon.svg";
import Linkedin from "../../../../assets/images_backup/linkedin-icon.svg";
import GDSLogo from "../../../../assets/images_backup/logo-gds.svg"
import LocationIcon from "../../../../assets/images_backup/location-icon.svg"
import PhoneIcon from "../../../../assets/images_backup/phone-icon.svg"
import { useNavigate } from 'react-router-dom';



function FooterDesktop() {
const navigate = useNavigate();
    return (
        <div className="footer-container">
            <div className="footer-sub-container-one">
                <div className="footer-logo" onClick={() => navigate("/")}>
                    <img src={GDSLogo} alt="GDS Logo" className="f-logo-img" />
                </div>
                <div className="fsco-one">© GDS Mining 2004. All Rights Reserved</div>
                <div className="fsco-two">Innovating to deliver the essential materials the world relies on.</div>
            </div>

            <div className="footer-sub-container-two">
                <div className="navigator-a" onClick={() => navigate("/about-us")}>About us</div>
                <div className="navigator-a" onClick={() => navigate("/current-project")}>Current Projects</div>
                <div className="navigator-a" onClick={() => navigate("/contact")}>Contact</div>
                <div className="navigator-a" onClick={() => navigate("/gallery")}>Gallery</div>
                {/* <div className="navigator-a" onClick={() => navigate("/more-ggm")}>Gold Global Market</div> */}
                {/* <div className="navigator-a" onClick={() => navigate("/more-gme")}>Gold Mining Exploration</div> */}

            </div>

            <div className="footer-sub-container-three">
                <div className="fsct-content">Stay connected with GDS for updates</div>
                <div className="sm-container">
                    <div><a href="https://www.linkedin.com/company/gds-mining"><img src={Linkedin} alt="LinkedIn"/></a></div>
                    {/* <div><img src={Instagram} alt="Instagram" /></div>
                    <div><img src={Facebook} alt="Facebook" /></div>
                    <div><img src={Twitter} alt="Twitter" /></div>
                    <div><img src={Youtube} alt="YouTube" /></div> */}
                </div>

                <div className="header-sc">
                    <div className="header-sub-container">
                        <div>
                            <img src={PhoneIcon} alt="Phone icon" />
                        </div>
                        <div>
                            <div className="header-label"><a href='tel:+8562057392727'>+856 20 5739 2727</a></div>
                            <div className="header-label"><a href="mailto:info@gdsmining.com">info@gdsmining.com</a></div>
                        </div>
                    </div>

                    <div className="header-sub-container">
                        <div>
                            <img src={LocationIcon} alt="Location icon" />
                        </div>
                        <div>
                            <div className="header-label">Ban Phonexai, District Saysettha</div>
                            <div className="header-label"><a href='https://maps.app.goo.gl/UW9PxSMpZZfFjZfr9'>View on Google Maps</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterDesktop;
