import React from 'react'
// import { useState } from 'react'
import "./ContactDesktop.css";
import Location from "../../../../assets/images/location-new-icon.jpg";
import Message from "../../../../assets/images/message-icon.jpg";
import Phone from "../../../../assets/images/phone-new-icon.jpg";
import Logo from "../../../../assets/images/logo-no-bg.png";

function ContactDesktop() {
  // const [formData, setFormData] = useState({
  //   fullName: '',
  //   email: '',
  //   phoneNumber: '',
  //   subject: 'General Inquiry',
  //   message: '',
  //   companyName: '',
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleSubjectChange = (subject) => {
  //   setFormData({ ...formData, subject });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };
  return (
    <div className='contact-us-main-container'>
      <div className='cu-first-container'>
        <div className='cu-heading'>Contact us</div>
        <div className='cuh-text'>We welcome your feedback, questions, and collaboration ideas. Feel free to reach out using the contact details below.</div>
      </div>

      <div className='map-form-container'>
        <div className='map-contt'>

          <div className='map-card'>
            <div className='logo-image-container'><img src={Logo} alt='logo' className='logo-image-gds'/></div>
            <div className='map-text-container'>
              <div className='map-card-text'>Ban Phonexai, District Saysetthai</div>
              <div className='map-card-text'> Vientiane, LAO PDR</div>
            </div>
          </div>
        </div>
        {/* <div className='form-contt'>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <label>Full Name</label>
              <input
                type="text"
                name="fullName"
                placeholder="Enter full name"
                value={formData.fullName}
                onChange={handleChange}
              />

              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter email id"
                value={formData.email}
                onChange={handleChange}
              />

              <label>Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                placeholder="Enter phone number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />

              <label>Subject</label>
              <div className="subject-container">
                {['General Inquiry', 'Investment', 'Partnerships', 'Mining Services', 'Other'].map((subject) => (
                  <button
                    type="button"
                    key={subject}
                    className={`subject-button ${formData.subject === subject ? 'selected' : ''}`}
                    onClick={() => handleSubjectChange(subject)}
                  >
                    {subject}
                  </button>
                ))}
              </div>

              <label>Message</label>
              <textarea
                name="message"
                placeholder="Enter Your Message"
                value={formData.message}
                onChange={handleChange}
              />

              <label>Company name</label>
              <input
                type="text"
                name="companyName"
                placeholder="Enter company name"
                value={formData.companyName}
                onChange={handleChange}
              />

              <button type="submit" className="submit-button">Send Message →</button>
            </form>
          </div>
        </div> */}
         <div className="cu-card-container">
          <div className='cu-card'>
            <div className='cu-card-first-container'><img src={Message} alt='message'/></div>
            <div className='cu-card-second-container'>
              <div className='cu-card-text-one'>Chat to support</div>
              <div className='cu-card-text-two'>We’re here to help</div>
              <div className='cu-card-text-three'><a href="mailto:info@gdsmining.com">info@gdsmining.com</a></div>
            </div>
          </div>
          <div className='cu-card'>
            <div className='cu-card-first-container'><img src={Location} alt='location'/></div>
            <div className='cu-card-second-container'>
              <div className='cu-card-text-one'>Visit us</div>
              <div className='cu-card-text-two'>Visit our office HQ.</div>
              <div className='cu-card-text-three'><a href='https://maps.app.goo.gl/UW9PxSMpZZfFjZfr9'>View on Google Maps</a></div>
            </div>
          </div>
          <div className='cu-card'>
            <div className='cu-card-first-container'><img src={Phone} alt='phone'/></div>
            <div className='cu-card-second-container'>
              <div className='cu-card-text-one'>Call us</div>
              <div className='cu-card-text-two'>Mon-Fri from 8am to 5pm.</div>
              <div className='cu-card-text-three'><a href='tel:+8562057392727'>+856 20 5739 2727</a></div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  )
}

export default ContactDesktop
