import React from 'react'
import "./GalleryMobile.css"
import GPhoto1 from "../../../../assets/images/g-photo-1.jpg"
import GPhoto2 from "../../../../assets/images/g-photo-2.jpg"
import GPhoto3 from "../../../../assets/images/g-photo-3.jpg"
import GPhoto4 from "../../../../assets/images/g-photo-4.jpg"
import GPhoto5 from "../../../../assets/images/g-photo-5.jpg"
import GPhoto6 from "../../../../assets/images/g-photo-6.jpg"
import GPhoto7 from "../../../../assets/images/g-photo-7.jpg"
import GPhoto8 from "../../../../assets/images/g-photo-8.jpg"
import SomImage from "../../../../assets/images/som-img.svg"
import GImage1 from "../../../../assets/images/GImage1.jpg"
import GImage2 from "../../../../assets/images/GImage2.jpg"
import GImage3 from "../../../../assets/images/GImage3.jpg"
import GImage4 from "../../../../assets/images/GImage4.jpg"
import GImage5 from "../../../../assets/images/GImage5.jpg"
import GImage6 from "../../../../assets/images/GImage6.jpg"
import GImage7 from "../../../../assets/images/GImage7.jpg"
import GImage8 from "../../../../assets/images/GImage8.jpg"
import GImage9 from "../../../../assets/images/GImage9.jpg"
import GImage10 from "../../../../assets/images/GImage10.jpg"
import GImage11 from "../../../../assets/images/GImage11.jpg"
import NewGPhoto1 from "../../../../assets/images/gallery-image-one.png";
import NewGPhoto2 from "../../../../assets/images/gallery-image-two.png"
import NewGPhoto3 from "../../../../assets/images/gallery-image-three.png"
import NewGPhoto4 from "../../../../assets/images/gallery-image-four.png"
import NewGPhoto5 from "../../../../assets/images/gallery-image-five.png"
import NewGPhoto6 from "../../../../assets/images/gallery-image-six.png"




function GalleryMobile() {
  return (
    <div className='mob-gallery-container'>
      <div className='mgc-first-contt'>
        <div className='mobile-km'>KEY MOMENTS</div>
        <div className='mob-km-text'>Explore pivotal moments in gold mining history and market evolution. From groundbreaking discoveries to major industry advancements, these key events have shaped the global gold market and its future.</div>
      </div>
      <div className='mm-cont'>
        <div class="marquee">
          <div className='mq-sc'>
            <><img src={GPhoto1} alt='g' className="img-marq-mobile" /></>
            <><img src={GPhoto2} alt='g' className="img-marq-mobile" /></>
            <><img src={GPhoto3} alt='g' className="img-marq-mobile" /></>
            <><img src={GPhoto4} alt='g' className="img-marq-mobile" /></>
            <><img src={GPhoto5} alt='g' className="img-marq-mobile" /></>
            <><img src={GPhoto6} alt='g' className="img-marq-mobile" /></>
            <><img src={GPhoto7} alt='g' className="img-marq-mobile" /></>
            <><img src={GPhoto8} alt='g' className="img-marq-mobile" /></>
          </div>
        </div>
      </div>

      <div className='images-container-mobile'>
        <img src={NewGPhoto1} alt='new' className='icm-image'/>
        <img src={NewGPhoto2} alt='new' className='icm-image'/>
        <img src={NewGPhoto3} alt='new' className='icm-image'/>
        <img src={NewGPhoto4} alt='new' className='icm-image'/>
        <img src={NewGPhoto5} alt='new' className='icm-image'/>
        <img src={NewGPhoto6} alt='new' className='icm-image'/>

      </div>
      <div className='mm-cont'>
        <div class="marquee">
          <div className='mq-scc'>
            <><img src={GImage1} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage2} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage3} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage4} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage5} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage6} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage7} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage8} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage9} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage10} alt='g' className="img-marq-mobile" /></>
            <><img src={GImage11} alt='g' className="img-marq-mobile" /></>
          </div> </div>
      </div>

      <div className='som-container'>
        <div className='som-text'>SOME OTHER MOMENTS</div>
        <div className='som-img-contt'><img src={SomImage} className='multi-image' alt='mi' /></div>
      </div>


    </div>
  )
}

export default GalleryMobile
