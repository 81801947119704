import React, { useState } from 'react'
import Logo from "../../../../assets/images_backup/logo-gds.svg";
import FavIcon from "../../../../assets/images/fav_icon.svg";
import CrossIcon from "../../../../assets/images/cross-icon.svg";
import Drawer from '@mui/material/Drawer';
import "./NavBarMobile.css";
import { Link, useNavigate } from 'react-router-dom';

function NavBarMobile() {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  return (
    <div className='m-navbar-conainer'>
      <div onClick={() => navigate('/')}><img src={Logo} alt='logo' /></div>
      <div className='button-fav-container' onClick={toggleDrawer(true)}><img src={FavIcon} alt='fav' /></div>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: '100%',
            backgroundColor: "#153C97",
            color: "#ffffff"
          },
        }}
      >
        <div
          role='presentation'
          onKeyDown={toggleDrawer(false)}
          className='drawer-container-mobile'
        >
          <div onClick={toggleDrawer(false)} className='close-drawer-btn'><img src={CrossIcon} alt='cross' /></div>
          <div className='link-mobile-container'>
            <Link to="/" onClick={toggleDrawer(false)} className='link-mobile-nav'>Home</Link>
            <Link to="/about-us" onClick={toggleDrawer(false)} className='link-mobile-nav'>About us</Link>
            <Link to="/current-project" onClick={toggleDrawer(false)} className='link-mobile-nav'>Current Projects</Link>
            <Link to="/contact" onClick={toggleDrawer(false)} className='link-mobile-nav'>Contact</Link>
            <Link to="/gallery" onClick={toggleDrawer(false)} className='link-mobile-nav'>Gallery</Link>
            <Link to="/global-gold-market" onClick={toggleDrawer(false)} className='link-mobile-nav'>Gold Global Market</Link>
            <Link to="/gold-mining-exploration" onClick={toggleDrawer(false)} className='link-mobile-nav'>Gold Mining Exploration</Link>
          </div>

        </div>
      </Drawer>
    </div>
  )
}

export default NavBarMobile





